.input-field {
  color: #353535;
  font-feature-settings: "cv11" on, "cv10" on, "cv04" on, "cv02" on, "cv05" on,
    "ss03" on, "cv01" on, "cv06" on, "cv03" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 22px; /* 157.143% */
}

.input-heading {
  color: var(--dark01-100, #1e1e1e);
  font-feature-settings: "cv11" on, "cv10" on, "cv04" on, "cv02" on, "cv05" on,
    "ss03" on, "cv01" on, "cv06" on, "cv03" on, "ss01" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 550 !important;
  line-height: 22px; /* 157.143% */
}
